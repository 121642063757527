var EIso3166OneAlpha2German;
(function(EIso3166OneAlpha2German) {
    EIso3166OneAlpha2German["AF"] = "Afghanistan";
    EIso3166OneAlpha2German["AX"] = "Aland-Inseln";
    EIso3166OneAlpha2German["AL"] = "Albanien";
    EIso3166OneAlpha2German["DZ"] = "Algerien";
    EIso3166OneAlpha2German["AS"] = "Amerikanisch-Samoa";
    EIso3166OneAlpha2German["AD"] = "Andorra";
    EIso3166OneAlpha2German["AO"] = "Angola";
    EIso3166OneAlpha2German["AI"] = "Anguilla";
    EIso3166OneAlpha2German["AQ"] = "Antarktis";
    EIso3166OneAlpha2German["AG"] = "Antigua und Barbuda";
    EIso3166OneAlpha2German["AR"] = "Argentinien";
    EIso3166OneAlpha2German["AM"] = "Armenien";
    EIso3166OneAlpha2German["AW"] = "Aruba";
    EIso3166OneAlpha2German["AU"] = "Australien";
    EIso3166OneAlpha2German["AT"] = "\xd6sterreich";
    EIso3166OneAlpha2German["AZ"] = "Aserbaidschan";
    EIso3166OneAlpha2German["BS"] = "Bahamas";
    EIso3166OneAlpha2German["BH"] = "Bahrain";
    EIso3166OneAlpha2German["BD"] = "Bangladesch";
    EIso3166OneAlpha2German["BB"] = "Barbados";
    EIso3166OneAlpha2German["BY"] = "Wei\xdfrussland";
    EIso3166OneAlpha2German["BE"] = "Belgien";
    EIso3166OneAlpha2German["BZ"] = "Belize";
    EIso3166OneAlpha2German["BJ"] = "Benin";
    EIso3166OneAlpha2German["BM"] = "Bermuda";
    EIso3166OneAlpha2German["BT"] = "Bhutan";
    EIso3166OneAlpha2German["BO"] = "Bolivien";
    EIso3166OneAlpha2German["BA"] = "Bosnien und Herzegowina";
    EIso3166OneAlpha2German["BW"] = "Botswana";
    EIso3166OneAlpha2German["BV"] = "Bouvetinsel";
    EIso3166OneAlpha2German["BR"] = "Brasilien";
    EIso3166OneAlpha2German["IO"] = "Britisches Territorium im Indischen Ozean";
    EIso3166OneAlpha2German["BN"] = "Brunei Darussalam";
    EIso3166OneAlpha2German["BG"] = "Bulgarien";
    EIso3166OneAlpha2German["BF"] = "Burkina Faso";
    EIso3166OneAlpha2German["BI"] = "Burundi";
    EIso3166OneAlpha2German["KH"] = "Kambodscha";
    EIso3166OneAlpha2German["CM"] = "Kamerun";
    EIso3166OneAlpha2German["CA"] = "Kanada";
    EIso3166OneAlpha2German["CV"] = "Kap Verde";
    EIso3166OneAlpha2German["KY"] = "Kaimaninseln";
    EIso3166OneAlpha2German["CF"] = "Zentralafrikanische Republik";
    EIso3166OneAlpha2German["TD"] = "Tschad";
    EIso3166OneAlpha2German["CL"] = "Chile";
    EIso3166OneAlpha2German["CN"] = "China";
    EIso3166OneAlpha2German["CX"] = "Weihnachtsinsel";
    EIso3166OneAlpha2German["CC"] = "Cocos (Keeling) Inseln";
    EIso3166OneAlpha2German["CO"] = "Kolumbien";
    EIso3166OneAlpha2German["KM"] = "Komoren";
    EIso3166OneAlpha2German["CG"] = "Kongo";
    EIso3166OneAlpha2German["CD"] = "Kongo (Demokratische Republik)";
    EIso3166OneAlpha2German["CK"] = "Cook-Inseln";
    EIso3166OneAlpha2German["CR"] = "Costa Rica";
    EIso3166OneAlpha2German["CI"] = "Elfenbeink\xfcste";
    EIso3166OneAlpha2German["HR"] = "Kroatien";
    EIso3166OneAlpha2German["CU"] = "Kuba";
    EIso3166OneAlpha2German["CY"] = "Zypern";
    EIso3166OneAlpha2German["CZ"] = "Tschechische Republik";
    EIso3166OneAlpha2German["DK"] = "D\xe4nemark";
    EIso3166OneAlpha2German["DJ"] = "Dschibuti";
    EIso3166OneAlpha2German["DM"] = "Dominica";
    EIso3166OneAlpha2German["DO"] = "Dominikanische Republik";
    EIso3166OneAlpha2German["EC"] = "Ecuador";
    EIso3166OneAlpha2German["EG"] = "\xc4gypten";
    EIso3166OneAlpha2German["SV"] = "El Salvador";
    EIso3166OneAlpha2German["GQ"] = "\xc4quatorialguinea";
    EIso3166OneAlpha2German["ER"] = "Eritrea";
    EIso3166OneAlpha2German["EE"] = "Estland";
    EIso3166OneAlpha2German["ET"] = "\xc4thiopien";
    EIso3166OneAlpha2German["FK"] = "Falklandinseln (Malwinen)";
    EIso3166OneAlpha2German["FO"] = "F\xe4r\xf6er Inseln";
    EIso3166OneAlpha2German["FJ"] = "Fidschi";
    EIso3166OneAlpha2German["FI"] = "Finnland";
    EIso3166OneAlpha2German["FR"] = "Frankreich";
    EIso3166OneAlpha2German["GF"] = "Franz\xf6sisch-Guayana";
    EIso3166OneAlpha2German["PF"] = "Franz\xf6sisch-Polynesien";
    EIso3166OneAlpha2German["TF"] = "Franz\xf6sische S\xfcdterritorien";
    EIso3166OneAlpha2German["GA"] = "Gabun";
    EIso3166OneAlpha2German["GM"] = "Gambia";
    EIso3166OneAlpha2German["GE"] = "Georgien";
    EIso3166OneAlpha2German["DE"] = "Deutschland";
    EIso3166OneAlpha2German["GH"] = "Ghana";
    EIso3166OneAlpha2German["GI"] = "Gibraltar";
    EIso3166OneAlpha2German["GR"] = "Griechenland";
    EIso3166OneAlpha2German["GL"] = "Gr\xf6nland";
    EIso3166OneAlpha2German["GD"] = "Grenada";
    EIso3166OneAlpha2German["GP"] = "Guadeloupe";
    EIso3166OneAlpha2German["GU"] = "Guam";
    EIso3166OneAlpha2German["GT"] = "Guatemala";
    EIso3166OneAlpha2German["GG"] = "Guernsey";
    EIso3166OneAlpha2German["GN"] = "Guinea";
    EIso3166OneAlpha2German["GW"] = "Guinea-Bissau";
    EIso3166OneAlpha2German["GY"] = "Guyana";
    EIso3166OneAlpha2German["HT"] = "Haiti";
    EIso3166OneAlpha2German["HM"] = "Heard Island & Mcdonald-Inseln";
    EIso3166OneAlpha2German["VA"] = "Heiliger Stuhl (Staat Vatikanstadt)";
    EIso3166OneAlpha2German["HN"] = "Honduras";
    EIso3166OneAlpha2German["HK"] = "Hongkong";
    EIso3166OneAlpha2German["HU"] = "Ungarn";
    EIso3166OneAlpha2German["IS"] = "Island";
    EIso3166OneAlpha2German["IN"] = "Indien";
    EIso3166OneAlpha2German["ID"] = "Indonesien";
    EIso3166OneAlpha2German["IR"] = "Iran (Islamische Republik)";
    EIso3166OneAlpha2German["IQ"] = "Irak";
    EIso3166OneAlpha2German["IE"] = "Irland";
    EIso3166OneAlpha2German["IM"] = "Isle Of Man";
    EIso3166OneAlpha2German["IL"] = "Israel";
    EIso3166OneAlpha2German["IT"] = "Italien";
    EIso3166OneAlpha2German["JM"] = "Jamaika";
    EIso3166OneAlpha2German["JP"] = "Japan";
    EIso3166OneAlpha2German["JE"] = "Jersey";
    EIso3166OneAlpha2German["JO"] = "Jordanien";
    EIso3166OneAlpha2German["KZ"] = "Kasachstan";
    EIso3166OneAlpha2German["KE"] = "Kenia";
    EIso3166OneAlpha2German["KI"] = "Kiribati";
    EIso3166OneAlpha2German["KR"] = "Korea";
    EIso3166OneAlpha2German["KP"] = "Nordkorea";
    EIso3166OneAlpha2German["KW"] = "Kuwait";
    EIso3166OneAlpha2German["KG"] = "Kirgisistan";
    EIso3166OneAlpha2German["LA"] = "Demokratische Volksrepublik Laos";
    EIso3166OneAlpha2German["LV"] = "Lettland";
    EIso3166OneAlpha2German["LB"] = "Libanon";
    EIso3166OneAlpha2German["LS"] = "Lesotho";
    EIso3166OneAlpha2German["LR"] = "Liberia";
    EIso3166OneAlpha2German["LY"] = "Libysch-Arabische Dschamahirija";
    EIso3166OneAlpha2German["LI"] = "Liechtenstein";
    EIso3166OneAlpha2German["LT"] = "Litauen";
    EIso3166OneAlpha2German["LU"] = "Luxemburg";
    EIso3166OneAlpha2German["MO"] = "Macau";
    EIso3166OneAlpha2German["MK"] = "Mazedonien";
    EIso3166OneAlpha2German["MG"] = "Madagaskar";
    EIso3166OneAlpha2German["MW"] = "Malawi";
    EIso3166OneAlpha2German["MY"] = "Malaysia";
    EIso3166OneAlpha2German["MV"] = "Malediven";
    EIso3166OneAlpha2German["ML"] = "Mali";
    EIso3166OneAlpha2German["MT"] = "Malta";
    EIso3166OneAlpha2German["MH"] = "Marshallinseln";
    EIso3166OneAlpha2German["MQ"] = "Martinique";
    EIso3166OneAlpha2German["MR"] = "Mauretanien";
    EIso3166OneAlpha2German["MU"] = "Mauritius";
    EIso3166OneAlpha2German["YT"] = "Mayotte";
    EIso3166OneAlpha2German["MX"] = "Mexiko";
    EIso3166OneAlpha2German["FM"] = "F\xf6derierte Staaten von Mikronesien";
    EIso3166OneAlpha2German["MD"] = "Moldawien";
    EIso3166OneAlpha2German["MC"] = "Monaco";
    EIso3166OneAlpha2German["MN"] = "Mongolei";
    EIso3166OneAlpha2German["ME"] = "Montenegro";
    EIso3166OneAlpha2German["MS"] = "Montserrat";
    EIso3166OneAlpha2German["MA"] = "Marokko";
    EIso3166OneAlpha2German["MZ"] = "Mosambik";
    EIso3166OneAlpha2German["MM"] = "Myanmar";
    EIso3166OneAlpha2German["NA"] = "Namibia";
    EIso3166OneAlpha2German["NR"] = "Nauru";
    EIso3166OneAlpha2German["NP"] = "Nepal";
    EIso3166OneAlpha2German["NL"] = "Niederlande";
    EIso3166OneAlpha2German["AN"] = "Niederl\xe4ndische Antillen";
    EIso3166OneAlpha2German["NC"] = "Neukaledonien";
    EIso3166OneAlpha2German["NZ"] = "Neuseeland";
    EIso3166OneAlpha2German["NI"] = "Nicaragua";
    EIso3166OneAlpha2German["NE"] = "Niger";
    EIso3166OneAlpha2German["NG"] = "Nigeria";
    EIso3166OneAlpha2German["NU"] = "Niue";
    EIso3166OneAlpha2German["NF"] = "Norfolkinsel";
    EIso3166OneAlpha2German["MP"] = "N\xf6rdliche Marianen";
    EIso3166OneAlpha2German["NO"] = "Norwegen";
    EIso3166OneAlpha2German["OM"] = "Oman";
    EIso3166OneAlpha2German["PK"] = "Pakistan";
    EIso3166OneAlpha2German["PW"] = "Palau";
    EIso3166OneAlpha2German["PS"] = "Pal\xe4stinensisches Territorium (besetzt)";
    EIso3166OneAlpha2German["PA"] = "Panama";
    EIso3166OneAlpha2German["PG"] = "Papua-Neuguinea";
    EIso3166OneAlpha2German["PY"] = "Paraguay";
    EIso3166OneAlpha2German["PE"] = "Peru";
    EIso3166OneAlpha2German["PH"] = "Philippinen";
    EIso3166OneAlpha2German["PN"] = "Pitcairn";
    EIso3166OneAlpha2German["PL"] = "Polen";
    EIso3166OneAlpha2German["PT"] = "Portugal";
    EIso3166OneAlpha2German["PR"] = "Puerto Rico";
    EIso3166OneAlpha2German["QA"] = "Katar";
    EIso3166OneAlpha2German["RE"] = "Reunion";
    EIso3166OneAlpha2German["RO"] = "Rum\xe4nien";
    EIso3166OneAlpha2German["RU"] = "Russische F\xf6deration";
    EIso3166OneAlpha2German["RW"] = "Ruanda";
    EIso3166OneAlpha2German["BL"] = "St. Barthelemy";
    EIso3166OneAlpha2German["SH"] = "St. Helena";
    EIso3166OneAlpha2German["KN"] = "St. Kitts und Nevis";
    EIso3166OneAlpha2German["LC"] = "St. Lucia";
    EIso3166OneAlpha2German["MF"] = "St. Martin";
    EIso3166OneAlpha2German["PM"] = "St. Pierre und Miquelon";
    EIso3166OneAlpha2German["VC"] = "St. Vincent und Grenadinen";
    EIso3166OneAlpha2German["WS"] = "Samoa";
    EIso3166OneAlpha2German["SM"] = "San Marino";
    EIso3166OneAlpha2German["ST"] = "Sao Tome und Principe";
    EIso3166OneAlpha2German["SA"] = "Saudi-Arabien";
    EIso3166OneAlpha2German["SN"] = "Senegal";
    EIso3166OneAlpha2German["RS"] = "Serbien";
    EIso3166OneAlpha2German["SC"] = "Seychellen";
    EIso3166OneAlpha2German["SL"] = "Sierra Leone";
    EIso3166OneAlpha2German["SG"] = "Singapur";
    EIso3166OneAlpha2German["SK"] = "Slowakei";
    EIso3166OneAlpha2German["SI"] = "Slowenien";
    EIso3166OneAlpha2German["SB"] = "Salomonen";
    EIso3166OneAlpha2German["SO"] = "Somalia";
    EIso3166OneAlpha2German["ZA"] = "S\xfcdafrika";
    EIso3166OneAlpha2German["GS"] = "S\xfcdgeorgien und Sandwichinseln";
    EIso3166OneAlpha2German["ES"] = "Spanien";
    EIso3166OneAlpha2German["LK"] = "Sri Lanka";
    EIso3166OneAlpha2German["SD"] = "Sudan";
    EIso3166OneAlpha2German["SR"] = "Surinam";
    EIso3166OneAlpha2German["SJ"] = "Svalbard und Jan Mayen";
    EIso3166OneAlpha2German["SZ"] = "Swasiland";
    EIso3166OneAlpha2German["SE"] = "Schweden";
    EIso3166OneAlpha2German["CH"] = "Schweiz";
    EIso3166OneAlpha2German["SY"] = "Syrische Arabische Republik";
    EIso3166OneAlpha2German["TW"] = "Taiwan";
    EIso3166OneAlpha2German["TJ"] = "Tadschikistan";
    EIso3166OneAlpha2German["TZ"] = "Tansania";
    EIso3166OneAlpha2German["TH"] = "Thailand";
    EIso3166OneAlpha2German["TL"] = "Timor-Leste";
    EIso3166OneAlpha2German["TG"] = "Togo";
    EIso3166OneAlpha2German["TK"] = "Tokelau";
    EIso3166OneAlpha2German["TO"] = "Tonga";
    EIso3166OneAlpha2German["TT"] = "Trinidad und Tobago";
    EIso3166OneAlpha2German["TN"] = "Tunesien";
    EIso3166OneAlpha2German["TR"] = "T\xfcrkei";
    EIso3166OneAlpha2German["TM"] = "Turkmenistan";
    EIso3166OneAlpha2German["TC"] = "Turks- und Caicosinseln";
    EIso3166OneAlpha2German["TV"] = "Tuvalu";
    EIso3166OneAlpha2German["UG"] = "Uganda";
    EIso3166OneAlpha2German["UA"] = "Ukraine";
    EIso3166OneAlpha2German["AE"] = "Vereinigte Arabische Emirate";
    EIso3166OneAlpha2German["GB"] = "Gro\xdfbritannien";
    EIso3166OneAlpha2German["US"] = "USA";
    EIso3166OneAlpha2German["UM"] = "Vorgelagerte Inseln der Vereinigten Staaten";
    EIso3166OneAlpha2German["UY"] = "Uruguay";
    EIso3166OneAlpha2German["UZ"] = "Usbekistan";
    EIso3166OneAlpha2German["VU"] = "Vanuatu";
    EIso3166OneAlpha2German["VE"] = "Venezuela";
    EIso3166OneAlpha2German["VN"] = "Vietnam";
    EIso3166OneAlpha2German["VG"] = "Jungferninseln (Britisch)";
    EIso3166OneAlpha2German["VI"] = "Jungferninseln (U.S.)";
    EIso3166OneAlpha2German["WF"] = "Wallis und Futuna";
    EIso3166OneAlpha2German["EH"] = "Westsahara";
    EIso3166OneAlpha2German["YE"] = "Jemen";
    EIso3166OneAlpha2German["ZM"] = "Sambia";
    EIso3166OneAlpha2German["ZW"] = "Simbabwe";
})(EIso3166OneAlpha2German || (EIso3166OneAlpha2German = {}));
var EIso3166OneAlpha2CountryGroupGerman;
(function(EIso3166OneAlpha2CountryGroupGerman) {
    EIso3166OneAlpha2CountryGroupGerman["AWS"] = "AWS";
    EIso3166OneAlpha2CountryGroupGerman["CLOUDFLARE"] = "Cloudflare";
    EIso3166OneAlpha2CountryGroupGerman["EU"] = "Europ\xe4ische Union (EU)";
    EIso3166OneAlpha2CountryGroupGerman["EEA"] = "Europ\xe4ischer Wirtschaftsraum (EEA)";
    EIso3166OneAlpha2CountryGroupGerman["FASTLY"] = "Fastly";
    EIso3166OneAlpha2CountryGroupGerman["GOOGLECLOUD"] = "Google Cloud";
    EIso3166OneAlpha2CountryGroupGerman["AUTOMATTIC"] = "Automattic";
    EIso3166OneAlpha2CountryGroupGerman["AKAMAI"] = "Akamai";
})(EIso3166OneAlpha2CountryGroupGerman || (EIso3166OneAlpha2CountryGroupGerman = {}));
export { EIso3166OneAlpha2German, EIso3166OneAlpha2CountryGroupGerman };
